import { ReferrerMeasure } from "./referrer_measure";

export class JigyoshoCardLinkArea {
  public init(): void {
    const $linkArea = $(".js-jigyosho-link-area");
    $linkArea.on("click", (e) => {
      e.preventDefault();
      const $target = $(e.currentTarget);
      let url = $target.closest(".js-jigyosho-card").data("jigyosho-url");
      url = ReferrerMeasure.appendMeasureParams(url);
      const id = $target.data("id");
      if (id) {
        url = url + "#" + id;
      }
      window.location.href = url;
    });

    $linkArea.on("mouseenter", this.mouseEnter);
    $linkArea.on("mouseleave", this.mouseleave);
  }

  private mouseEnter(): void {
    const $this = $(this);
    $this.css("cursor", "pointer");
    $this.addClass("u-link");
  }

  private mouseleave(): void {
    const $this = $(this);
    $this.css("cursor", "auto");
    $this.removeClass("u-link");
  }
}
