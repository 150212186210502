import "../../../app/assets/stylesheets/user/application.scss";
// library
import "jquery.easing";
import * as smoothscroll from "smoothscroll-polyfill"; // for safari, IE, edge
// common component
import { DropDown } from "./common/drop_down";
import { DropdownMenu } from "./common/dropdown_menu";
import { Modal } from "./common/modal";
import { Notification } from "./common/notification";
import { ParsleyFormLoader } from "./common/parsley_form_loader";
import { ShowMoreResp } from "./common/show_more_responsive";
import { SlideNav } from "./common/slide_nav";
import { UserSentry } from "./common/user_sentry";
import { WarnLeavePage } from "./common/warn_leave_page";
import { Conversion } from "./common/conversion";
import { ImageSandbox } from "./common/image_sandbox";
import { ElementScroll } from "./common/element_scroll";
import { ElementClickable } from "./common/element_clickable";
import { Address } from "./common/address";
import { BrochureJigyosho } from "./common/brochure_jigyosho";
import { SlideImages } from "./common/slide_images";
import { BrochureFooter } from "./common/brochure_footer";
import { FixedSidebar } from "./common/fixed_sidebar";
import { AjaxHelper } from "./util/ajax_helper";
import { ScrollBar } from "./common/scroll_bar";
import { HideFavoriteModal } from "./common/hide_favorite_modal";
import { ImpressionMeasure } from "./common/impression_measure";
import { FixedTel } from "./common/fixed_tel";
import { JigyoshoCardLinkArea } from "./common/jigyosho_card_link_area";

// globalでJqueryが使えるようにする
window.$ = $;
// fix lighthouse [Does not use passive listeners to improve scrolling performance]
jQuery.event.special.touchstart = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup: function (_, ns, handle: any) {
    this.addEventListener("touchstart", handle, {
      passive: !ns.includes("noPreventDefault"),
    });
  },
};
jQuery.event.special.touchmove = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup: function (_, ns, handle: any) {
    this.addEventListener("touchmove", handle, {
      passive: !ns.includes("noPreventDefault"),
    });
  },
};

window.onload = (): void => {
  // for all page
  smoothscroll.polyfill();
  AjaxHelper.setRequestFilter();
  new UserSentry().init();
  new DropdownMenu().init();
  new SlideNav().init();
  DropDown.init();
  ShowMoreResp.init();
  new Modal().init();
  WarnLeavePage.init();
  ParsleyFormLoader.load();
  Notification.init();
  new Conversion().init();
  ImageSandbox.init();
  ElementScroll.init();
  ElementClickable.bind();
  new BrochureJigyosho().init();
  new Address().init();
  new SlideImages().init();
  new BrochureFooter().init();
  FixedSidebar.getInstance();
  ScrollBar.init();
  new HideFavoriteModal().init();
  ImpressionMeasure.init();
  new FixedTel().init();
  new JigyoshoCardLinkArea().init();

  // for specific page
  const pageID = $(".js-page-id").data("page-id");
  switch (pageID) {
    case "top/index":
      import(
        /* webpackChunkName: 'user/top_index' */ "./pages/top_index/index"
      ).then(({ TopIndex }) => {
        TopIndex.init();
      });
      break;
    case "jigyosho_ranking/index":
      import(
        /* webpackChunkName: 'user/jigyosho_ranking_index' */ "./pages/jigyosho_ranking_index/index"
      ).then(({ JigyoshoRankingIndex }) => {
        JigyoshoRankingIndex.init();
      });
      break;
    case "jigyosho_search/index":
      import(
        /* webpackChunkName: 'user/jigyosho_search_index' */ "./pages/jigyosho_search_index/index"
      ).then(({ JigyoshoSearchIndex }) => {
        JigyoshoSearchIndex.init();
      });
      break;
    case "jigyosho/show":
      import(
        /* webpackChunkName: 'user/jigyosho_show' */ "./pages/jigyosho_show/index"
      ).then(({ JigyoshoShow }) => {
        JigyoshoShow.init();
      });
      break;
    case "jigyosho/preview":
      import(
        /* webpackChunkName: 'user/jigyosho_show' */ "./pages/jigyosho_show/index"
      ).then(({ JigyoshoShow }) => {
        JigyoshoShow.initPreview();
      });
      break;
    case "brochure/index":
      import(
        /* webpackChunkName: 'user/brochure_index' */ "./pages/brochure_index/index"
      ).then(({ BrochureIndex }) => {
        BrochureIndex.init();
      });
      break;
    case "special4/index":
      import(
        /* webpackChunkName: 'user/special4_index' */ "./pages/special4_index/index"
      ).then(({ Special4Index }) => {
        Special4Index.init();
      });
      break;
    case "ownership/show":
      import(
        /* webpackChunkName: 'user/ownership_show' */ "./pages/ownership_show/index"
      ).then(({ OwnershipShow }) => {
        OwnershipShow.init();
      });
      break;
    case "guide/show":
      import(
        /* webpackChunkName: 'user/guide_show' */ "./pages/guide_show/index"
      ).then(({ GuideShow }) => {
        GuideShow.init();
      });
      break;
    case "guide/index":
      import(
        /* webpackChunkName: 'user/guide_index' */ "./pages/guide_index/index"
      ).then(({ GuideIndex }) => {
        GuideIndex.init();
      });
      break;
    case "news/index":
      import(
        /* webpackChunkName: 'user/news_index' */ "./pages/news_index/index"
      ).then(({ NewsIndex }) => {
        NewsIndex.init();
      });
      break;
    case "support/line_support":
      import(
        /* webpackChunkName: 'user/line_support' */ "./pages/line_support/index"
      ).then(({ LineSupport }) => {
        LineSupport.init();
      });
      break;
    case "support/consultant":
      import(
        /* webpackChunkName: 'user/support_consultant' */ "./pages/support/consultant"
      ).then(({ Consultant }) => {
        Consultant.init();
      });
      break;
    case "event/show":
      import(
        /* webpackChunkName: 'user/event_show' */ "./pages/event_show/index"
      ).then(({ EventShow }) => {
        EventShow.init();
      });
      break;
    case "cashback/new":
      import(
        /* webpackChunkName: 'user/cashback_new' */ "./pages/cashback/index"
      ).then(({ Cashback }) => {
        Cashback.init();
      });
      break;
    case "cashback/top":
      import(
        /* webpackChunkName: 'user/cashback_top' */ "./pages/cashback_top/index"
      ).then(({ CashbackTop }) => {
        CashbackTop.init();
      });
      break;
    case "pages/show":
      import(
        /* webpackChunkName: 'user/page_show' */ "./pages/pages_show"
      ).then(({ PagesShow }) => {
        PagesShow.init();
      });
      break;
    case "user_requester_brochure/index":
      import(
        /* webpackChunkName: 'user/user_requester_brochure_index' */ "./pages/user_requester_brochure_index"
      ).then(({ UserRequesterBrochureIndex }) => {
        UserRequesterBrochureIndex.init();
      });
      break;
    case "jigyosho_articles/preview":
      import(
        /* webpackChunkName: 'user/jigyosho_articles_preview' */ "./pages/jigyosho_article"
      ).then(({ JigyoshoArticle }) => {
        JigyoshoArticle.init();
      });
      break;
    case "condition/new":
      import(
        /* webpackChunkName: 'user/condition_new' */ "./pages/user_requester_desired_condition/"
      ).then(({ DesiredCondition }) => {
        DesiredCondition.init();
      });
      break;
    case "messages/index":
      import(
        /* webpackChunkName: 'user/condition_new' */ "./pages/messages_index/"
      ).then(({ MessagesIndex }) => {
        MessagesIndex.init();
      });
      break;
    case "desired_conditions/edit":
      import(
        /* webpackChunkName: 'user/condition_new' */ "./pages/desired_conditions_edit/"
      ).then(({ DesiredConditionsEdit }) => {
        DesiredConditionsEdit.init();
      });
      break;
  }
};
