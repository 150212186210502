export class ReferrerMeasure {
  static readonly REF_PAGE_KEY: string = "ref_page";
  static readonly REF_POS_KEY: string = "ref_pos";
  static readonly REF_CATEGORY_KEY: string = "ref_category";

  public static init(): void {
    if (!this.enableMeasure()) {
      return;
    }

    $("a").on("click", (e) => {
      e.preventDefault();
      const href = $(e.currentTarget).attr("href");
      window.location.href = this.appendMeasureParams(href);
    });
  }

  public static appendMeasureParams(href: string): string {
    if (!this.enableMeasure()) {
      return href;
    }

    let urlStr = href;
    if (!urlStr.includes(window.location.host)) {
      urlStr = window.location.origin + urlStr;
    }
    const url = new URL(urlStr);

    const urlParams = new URL(window.location.href).searchParams;
    const refPage = urlParams.get(this.REF_PAGE_KEY);
    const refPos = urlParams.get(this.REF_POS_KEY);
    const refCategory = urlParams.get(this.REF_CATEGORY_KEY);

    url.searchParams.set(this.REF_PAGE_KEY, refPage);
    url.searchParams.set(this.REF_POS_KEY, refPos);
    url.searchParams.set(this.REF_CATEGORY_KEY, refCategory);
    return url.toString();
  }

  private static enableMeasure(): boolean {
    const urlParams = new URL(window.location.href).searchParams;
    return (
      urlParams.has(this.REF_PAGE_KEY) &&
      urlParams.has(this.REF_POS_KEY) &&
      urlParams.has(this.REF_CATEGORY_KEY)
    );
  }
}
